import { Box, Stack, Typography } from '@mui/material';
import useLocales from '#/hooks/useLocales';
import { agb, privacyPolicy } from '#/components/pages/TCPP/mockTerms';
import Iconify from '#/components/shared/ui/Iconify';

export default function AGB() {
  const { translate } = useLocales();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        py: 10,
        px: {
          xs: 2,
          md: 15,
        },
        gap: 8,
      }}
    >
      <Typography variant="h1" textAlign="center">
        {String(translate('agb.title'))}
      </Typography>
      <Typography variant="body1">
        {String(translate('global.lastUpdated'))}{' '}
        {new Date().toLocaleDateString()}
      </Typography>
      {agb.map((pp, index) => {
        const section = pp?.value;
        return (
          <Stack key={index} spacing={2}>
            <Typography variant="h5">
              {String(translate(`agb.${section}.title`))}
            </Typography>

            {pp.content &&
              pp.content.map((content, index) => (
                <Typography key={index} variant="body1">
                  {String(
                    translate(`agb.${section}.content.${content}`, {
                      returnObjects: true,
                    })
                  )}
                </Typography>
              ))}
            {pp.subContent &&
              pp.subContent.map((subContent, index) => (
                <Stack key={index} direction="row" spacing={1}>
                  <Iconify icon="radix-icons:dot-filled" />
                  <Typography variant="body1">
                    {String(
                      translate(`agb.${section}.content.${subContent}`, {
                        returnObjects: true,
                      })
                    )}
                  </Typography>
                </Stack>
              ))}
          </Stack>
        );
      })}
    </Box>
  );
}
